import styled from "@emotion/styled";
import React, { useCallback, useEffect, useState } from "react";

import { Button } from "~src/designSystem/atoms/Button";
import { ListBoxItem } from "~src/designSystem/atoms/ListBoxItem";
import { OldInputGoUseTextfield } from "~src/designSystem/deprecated/OldInput";
import { Spacer } from "~src/designSystem/layout/Spacer";
import { DIALOG_FULLPAGE_BREAKPOINT } from "~src/designSystem/molecules/Dialog";
import { t } from "~src/designSystem/theme";
import { DataSourceLayout } from "~src/shared/dataSources/components/dataSourceLayout";
import {
  getDataSourcesSearchModalAnalyticsEvent,
  getDataSourcesSearchModalListOptionEvent,
} from "~src/shared/dataSources/tracking/utils";
import { IIntegration, useDataSourcesList } from "~src/shared/dataSources/useDataSourcesList";
import { DATA_SOURCE_TEXTS } from "~src/shared/dataSources/utils";
import { callRequest } from "~src/shared/requests/useRequest";
import { useStepper } from "~src/shared/stepper/stepperContext";
import { MOBILE_MAX } from "~src/shared/styles/responsive";
import { useAnalytics } from "~src/shared/thirdParties/segment";
import { IConnectDataSourceFlowSource, ICountryCode, IIntegrationType } from "~src/shared/types";
import { vendorRequests } from "~src/vendor/requests";

import { CSV_ACCOUNTING_MANAGER } from "../../accounting/accountingPlatforms";
import { CSV_BILLING_MANAGER } from "../../billing/billingManagers";
import { OPTIONS_LIST_SINGLE_COLUMN_BREAKPOINT } from "../../breakpoints";
import { ContactSupport } from "../ContactSupport";
import { DataSourceLayoutButtonSection } from "../dataSourceLayout/DataSourceLayoutButtonSection";
import { ConnectDataSourceSearching } from "./ConnectDataSourceSearching";
import { ConnectDataSourceSearchNoResults } from "./ConnectDataSourceSearchNoResults";

export interface IConnectDataSourceSearchProps {
  type: IIntegrationType;
  onSelect: (integration: IIntegration, query?: string) => void;
  /**
   * Where in the app was the connect data source modal flow initiated.
   * Mainly used to product analytics purposes.
   */
  source: IConnectDataSourceFlowSource;
  country?: ICountryCode;
}

export const ConnectDataSourceSearch: React.FC<IConnectDataSourceSearchProps> = (props) => {
  const { type, onSelect, source, country } = props;
  const { removeTopOfStack, currentStackIndex } = useStepper();

  const { trackEvent, trackPage } = useAnalytics();
  // Log modal impression in Segment
  useEffect(() => {
    const event = getDataSourcesSearchModalAnalyticsEvent(type, source);
    if (event !== null) {
      trackPage(event);
    }
  }, [source, trackPage, type]);

  const logOptionClickEvent = (integration: IIntegration) => {
    const event = getDataSourcesSearchModalListOptionEvent(type, source);
    if (event != null) {
      trackEvent(event, {
        integration_id: integration.key,
        integration_name: integration.name,
        integration_type: type,
      });
    }
  };

  const [query, setQuery] = useState<string>("");
  const { search, dataSources } = useDataSourcesList({ type });

  const [results, setResults] = useState<readonly IIntegration[] | null>(dataSources);
  const [searching, setSearching] = useState<boolean>(false);

  const onQueryChange = useCallback(
    (q: string) => {
      setQuery(q);

      if (q === "") {
        setResults(dataSources);
        setSearching(false);
        return;
      }

      setSearching(true);
      search(q).then((ints) => {
        if (ints !== null) {
          setResults(ints);
        } else {
          setResults(null);
        }
        setSearching(false);
      });
    },
    [dataSources, search],
  );

  useEffect(() => {
    if (query.length > 3 && (results === null || results.length === 0)) {
      (async () => {
        await callRequest(vendorRequests.saveIntegrationsQuery({ query, context: type }));
      })();
    }
  }, [query, results, type]);

  const onNoResultsUploadCSVClick = useCallback(() => {
    switch (type) {
      case "accounting": {
        onSelect(CSV_ACCOUNTING_MANAGER, query);
        break;
      }
      case "billing_manager": {
        onSelect(CSV_BILLING_MANAGER, query);
        break;
      }
      default: {
        throw new Error(`Unexpected integration type=${type}`);
      }
    }
  }, [onSelect, query, type]);

  return (
    <DataSourceLayout
      heading={`Securely add your ${DATA_SOURCE_TEXTS[type].name}`}
      description={DATA_SOURCE_TEXTS[type].description}
    >
      <SearchInput
        placeholder="Search for a data source"
        value={query}
        onChange={(e) => {
          onQueryChange(e.target.value);
        }}
      />
      {searching ? (
        <ConnectDataSourceSearching />
      ) : results === null || results.length === 0 ? (
        <ConnectDataSourceSearchNoResults
          onUploadCSVClick={onNoResultsUploadCSVClick}
          type={type}
          query={query}
        />
      ) : (
        <>
          <OptionsList>
            {results.map((result) => (
              <div key={result.key}>
                <ListBoxItem
                  isCenterSecondaryAction
                  icon={result.logo}
                  label={result.name}
                  description={result.subtitle}
                  secondaryAction={
                    <Button
                      onClick={() => {
                        // Log which data source was clicked in Segment
                        logOptionClickEvent(result);
                        onSelect(result, query);
                      }}
                      kind="secondary"
                      size="small"
                    >
                      Connect
                    </Button>
                  }
                />
              </div>
            ))}
          </OptionsList>
          <Spacer y="6" />
        </>
      )}
      <ContactSupport country={country} integrationType={type} />
      {currentStackIndex > 0 && (
        <ButtonSectionWrapper>
          <DataSourceLayoutButtonSection
            secondaryButtonText="Back"
            secondaryButtonAction={removeTopOfStack}
          />
        </ButtonSectionWrapper>
      )}
    </DataSourceLayout>
  );
};

const ButtonSectionWrapper = styled.div`
  margin-top: ${t.space[5].toString()};
`;

const OptionsList = styled.div`
  overflow: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${t.c.spacing("3", "5")};
  padding-top: ${t.space[3].toString()};

  > div {
    padding-bottom: ${t.space[3].toString()};
  }
  > div:nth-last-child(n + 3) {
    border-bottom: ${({ theme }) => `1px solid ${theme.components.Divider.default}`};
  }

  @media (max-width: ${OPTIONS_LIST_SINGLE_COLUMN_BREAKPOINT}px) and (min-width: ${DIALOG_FULLPAGE_BREAKPOINT}px) {
    grid-template-columns: 1fr;

    > div:nth-last-child(n + 2) {
      border-bottom: ${({ theme }) => `1px solid ${theme.components.Divider.default}`};
    }
  }
  @media (max-width: ${MOBILE_MAX}) {
    grid-template-columns: 1fr;

    > div:nth-last-child(n + 2) {
      border-bottom: ${({ theme }) => `1px solid ${theme.components.Divider.default}`};
    }
  }
`;

const SearchInput = styled(OldInputGoUseTextfield)`
  margin: ${t.space[5].toString()} 0;
`;
