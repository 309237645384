import { styled } from "~src/designSystem/theme";
import {
  backgroundColorStorybookArgs,
  backgroundColorVariants,
  borderRadiusStorybookArgs,
  borderRadiusVariants,
  contentColorStorybookArgs,
  contentColorVariants,
  marginStorybookArgs,
  marginVariants,
  paddingStorybookArgs,
  paddingVariants,
  shadowStorybookArgs,
  shadowVariants,
  sizeStorybookArgs,
  sizeVariants,
} from "~src/designSystem/theme/variants";
import { castToStitchesStoryComponent } from "~src/storybook/utils";

export const View = styled(
  "div",
  backgroundColorVariants,
  borderRadiusVariants,
  contentColorVariants,
  marginVariants,
  paddingVariants,
  shadowVariants,
  sizeVariants,
  {
    // Standardize this one later. For now, lazy.
    variants: {
      overflow: {
        auto: {
          overflow: "auto",
        },
      },
    },
  },
);

export const StorybookView = castToStitchesStoryComponent(View);

export const StorybookViewArgs = {
  ...backgroundColorStorybookArgs,
  ...borderRadiusStorybookArgs,
  ...contentColorStorybookArgs,
  ...marginStorybookArgs,
  ...paddingStorybookArgs,
  ...shadowStorybookArgs,
  ...sizeStorybookArgs,
};
