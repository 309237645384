import styled from "@emotion/styled";
import { MouseEventHandler } from "react";

import { Button } from "~src/designSystem/atoms/Button";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { IImageOrSVG, ImageOrSVG } from "~src/designSystem/sortLater/ImageOrSVG";
import { t } from "~src/designSystem/theme";
import { DataSourceHeadingDescriptionPairing } from "~src/shared/dataSources/components/DataSourceHeadingDescriptionPairing";
import { DataSourceModalWrapper } from "~src/shared/dataSources/components/DataSourceModalWrapper";

export interface IDataSourceInformationLayoutProps {
  heading: string;
  description: string | React.ReactNode;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  primaryButtonAction?: MouseEventHandler<HTMLButtonElement>;
  secondaryButtonAction?: MouseEventHandler<HTMLButtonElement>;
  imageSrc?: IImageOrSVG;
}

export const DataSourceInformationLayout: React.FC<IDataSourceInformationLayoutProps> = ({
  heading,
  description,
  imageSrc,
  primaryButtonText,
  primaryButtonAction,
  secondaryButtonText,
  secondaryButtonAction,
}) => {
  return (
    <DataSourceModalWrapper>
      {imageSrc != null && (
        <ImageWrapper>
          <ImageOrSVG width={104} height={104} src={imageSrc} />
        </ImageWrapper>
      )}
      <HeadingPairingWrapper>
        <DataSourceHeadingDescriptionPairing heading={heading} description={description} />
      </HeadingPairingWrapper>
      <ButtonSectionWrapper>
        {primaryButtonText != null && (
          <Button onClick={primaryButtonAction} size="large" kind="primary">
            {primaryButtonText}
          </Button>
        )}
        {secondaryButtonText != null && (
          <Button onClick={secondaryButtonAction} size="large" kind="secondary">
            {secondaryButtonText}
          </Button>
        )}
      </ButtonSectionWrapper>
    </DataSourceModalWrapper>
  );
};

const HeadingPairingWrapper = styled.div`
  text-align: center;
`;

const ButtonSectionWrapper = styled(Flexbox)`
  gap: ${t.space[4].toString()};
  align-items: center;
  margin-top: ${t.space[4].toString()};

  > button {
    width: 300px;
  }
`;

const ImageWrapper = styled(Flexbox)`
  height: 250px;
  align-items: center;
  justify-content: center;
  margin-bottom: ${t.space[4].toString()};
`;
