/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import React from "react";
import { UrlObject } from "url";

import { user_type_enum_enum } from "~src/__generated__/graphql/types";
import { useRestoreFromDemo } from "~src/admin/demos/hooks/useRestoreFromDemo";
import { Popover } from "~src/designSystem/atoms/Popover";
import { UserAvatar } from "~src/designSystem/atoms/UserAvatar";
import { View } from "~src/designSystem/atoms/View";
import { OldButtonTryToAvoid } from "~src/designSystem/deprecated/OldButton";
import { OldSpanUseTextInstead } from "~src/designSystem/deprecated/Span";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { t } from "~src/designSystem/theme";
import { useAuthContext } from "~src/shared/auth/AuthProvider";
import { useVendorImpersonateStore } from "~src/shared/auth/store";
import { useUser } from "~src/shared/auth/useUser";
import { DataSourcesBadge } from "~src/shared/dataSources/components/DataSourcesBadge";
import { NotificationsBadge } from "~src/shared/dataSourcesRequirements/components/NotificationsBadge";
import { useEnv } from "~src/shared/env/useEnv";
import { IFeatureFlagName, useFeatureFlagChecker } from "~src/shared/featureFlags";

import { CommandIcon } from "./CommandIcon";
import { DarkModeSwitcher } from "./DarkModeSwitcher";
import { NavItem } from "./NavItem";
import { NavItemWrapper } from "./NavItemWrapper";
import { PipeLogo } from "./PipeLogo";
import { ProfileButtonContent } from "./ProfileButtonContent";

export const TOPBAR_HEIGHT = 57;

export type ITab = {
  title: string;
  href: string | UrlObject;
  // Whether to show this tab on production. Defaults to true.
  showOnProduction?: boolean;
  // If provided, we only show this tab when the feature flag is active.
  featureFlag?: IFeatureFlagName;
  // Highlight the tab if the current URL includes this string.
  highlightPathIncludes?: string[];
};

type IProps = {
  onSearchClick?: () => void;
  /** Navigation tabs. */
  navItems: readonly ITab[];
  brandLinkHref: string;
};

export const Header: React.FC<IProps> = ({ brandLinkHref, navItems, onSearchClick }) => {
  const user = useUser();
  const router = useRouter();
  const env = useEnv();
  const { logout } = useAuthContext();

  const featureFlagChecker = useFeatureFlagChecker();

  const impersonateVendorPublicID = useVendorImpersonateStore(
    (state) => state.impersonateVendorPublicID,
  );
  const setImpersonateVendorPublicID = useVendorImpersonateStore(
    (state) => state.setImpersonateVendorPublicID,
  );

  const removeImpersonation = async () => {
    setImpersonateVendorPublicID(null);
    window.location.assign("/admin");
  };

  // We don't use the values here, but we kick off the useEffect hook.
  useRestoreFromDemo();

  return (
    <TopBar>
      <LogoWrapper href={brandLinkHref} icon={<PipeLogo />} highlight={false} />
      <Divider />
      {/**
       * TODO(md): Don't do it like this... have different components
       * for each user type instead of writing it all conditionally here.
       */}
      {user.userType === user_type_enum_enum.pipe_admin && (
        <>
          <IconsSection>
            {onSearchClick !== undefined && (
              <NavItem
                onClick={onSearchClick}
                title="Command Center"
                icon={
                  <div style={{ width: "16px", height: "16px" }}>
                    <CommandIcon />
                  </div>
                }
              />
            )}
            <DarkModeSwitcher />
          </IconsSection>
          <Divider />
        </>
      )}
      <NavSection>
        {navItems.map((tab) => {
          if (tab.showOnProduction === false && env.API_URL.includes("api.pipe.com")) {
            return null;
          }

          if (tab.featureFlag !== undefined && !featureFlagChecker(tab.featureFlag)) {
            return null;
          }

          return (
            <NavItem
              key={tab.title}
              title={tab.title}
              href={tab.href}
              highlightPathIncludes={tab.highlightPathIncludes}
            />
          );
        })}
      </NavSection>
      <Flexbox marginAutoLeft />
      {user.userType === user_type_enum_enum.vendor_admin && (
        <>
          <View css={{ paddingRight: "10px" }}>
            <DataSourcesBadge
              onClick={
                router.pathname === "/settings/data-sources"
                  ? undefined
                  : () => router.push("/settings/data-sources")
              }
            />
          </View>
          <NotificationsBadge />
        </>
      )}
      {/* This is ugly but I don't care. One day we will upgrade this. */}
      {impersonateVendorPublicID !== null && (
        <>
          <OldSpanUseTextInstead color="error">
            YOU ARE IMPERSONATING A VENDOR
          </OldSpanUseTextInstead>
          <OldButtonTryToAvoid secondary onClick={removeImpersonation}>
            Stop Impersonating
          </OldButtonTryToAvoid>
        </>
      )}
      <Popover placement="bottom right" offset={8}>
        <button id="nav_bar_profile_button" style={{ background: "transparent" }}>
          <NavItemWrapper icon={<UserAvatar size="24px" name={user.name} />} />
        </button>
        <ProfileButtonContent user={user} logout={logout} />
      </Popover>
    </TopBar>
  );
};

const Divider = styled.div`
  height: 32px;
  width: 0;
  margin: 0 ${t.space[1].toString()};
  border-left: 1px solid ${({ theme }) => theme.components.Divider.default};
`;

const NavSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${t.space[2].toString()};
`;

const IconsSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${t.space[1].toString()};
`;

const TopBar = styled.div`
  background: ${({ theme }) => theme.components.NavBar.Header.background};
  height: ${TOPBAR_HEIGHT}px;

  width: 100%;
  gap: ${t.space[2].toString()};
  padding: 0 ${t.space[4].toString()} 0 28px;
  display: flex;
  align-items: center;

  border-bottom: 1px solid ${({ theme }) => theme.components.Divider.default};

  /* https://pipe-financial.slack.com/archives/CPJTBFPKJ/p1630093533058300 */
  overflow-x: auto;
`;

const LogoWrapper = styled(NavItem)`
  color: ${({ theme }) => theme.components.NavBar.Header.logo};
`;
