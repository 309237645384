import React, { useEffect } from "react";

import { Button } from "~src/designSystem/atoms/Button";
import { OldSpanUseTextInstead } from "~src/designSystem/deprecated/Span";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { Spacer } from "~src/designSystem/layout/Spacer";
import { DATA_SOURCE_TEXTS } from "~src/shared/dataSources/utils";
import { ISegmentTrackEvent, useAnalytics } from "~src/shared/thirdParties/segment";
import { IIntegrationType } from "~src/shared/types";

interface IProps {
  type: IIntegrationType;
  query: string;
  onUploadCSVClick: () => void;
}

export const ConnectDataSourceSearchNoResults: React.FC<IProps> = ({
  type,
  query,
  onUploadCSVClick,
}: IProps) => {
  const integ = DATA_SOURCE_TEXTS[type].search;

  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(ISegmentTrackEvent.MissingIntegration, {
      integration: query,
    });
  }, [query, trackEvent]);

  return (
    <Flexbox alignItems="center">
      <Spacer y="8" />
      <OldSpanUseTextInstead color="muted">Can&apos;t find your {integ}?</OldSpanUseTextInstead>
      <Spacer y="8" />
      <Button kind="primary" size="small" onClick={onUploadCSVClick}>
        Upload CSV
      </Button>
      <Spacer y="8" />
    </Flexbox>
  );
};
