import styled from "@emotion/styled";
import React from "react";

import { Button } from "~src/designSystem/atoms/Button";
import { ListBoxItem } from "~src/designSystem/atoms/ListBoxItem";
import { Accounting } from "~src/designSystem/icons/Accounting";
import { Bank } from "~src/designSystem/icons/Bank";
import { Revenues } from "~src/designSystem/icons/Revenues";
import { t } from "~src/designSystem/theme";
import { useUser } from "~src/shared/auth/useUser";
import { pluralizer } from "~src/shared/helpers";
import { IConnectDataSourceFlowSource } from "~src/shared/types";

import { useConnectAccounting } from "../../accounting/hooks/useConnectAccounting";
import { IAllowedRedirectPath } from "../../allowedRedirectPaths";
import { useConnectBank } from "../../bank/hooks/useConnectBank";
import { useConnectBilling } from "../../billing/hooks/useConnectBilling";
import { ContactSupport } from "../../components/ContactSupport";
import { DataSourceLayout } from "../../components/dataSourceLayout";
import { useVendorDataSources } from "../../useVendorDataSources";

export interface IProps {
  onSuccess: () => Promise<void>;
  redirectPath: IAllowedRedirectPath;
  source: IConnectDataSourceFlowSource;
}

export const AddDataSource: React.FC<IProps> = ({ onSuccess, redirectPath, source }) => {
  const openAccounting = useConnectAccounting({
    onSuccess,
    redirectPath,
    source,
  });
  const { open: openPlaid } = useConnectBank({
    onSuccess,
    redirectPath,
    source,
  });
  const openBilling = useConnectBilling({
    onSuccess,
    redirectPath,
    source,
  });
  const { country } = useUser().vendor;
  const { data } = useVendorDataSources();

  const numConnectedDataSources = data.dataSources.reduce(
    (numSourcesMap, ds) => {
      if (ds.isAccounting) {
        numSourcesMap.accounting += 1;
      } else if (ds.isBank) {
        numSourcesMap.bank += 1;
      } else if (ds.isRevenue) {
        numSourcesMap.billing += 1;
      }
      return numSourcesMap;
    },
    { accounting: 0, bank: 0, billing: 0 },
  );

  const DATA_SOURCE_CATEGORIES = [
    {
      label: "Bank",
      description: "Connect your bank account.",
      action: openPlaid,
      numSourcesAdded: numConnectedDataSources.bank,
      icon: <Bank />,
    },
    {
      label: "Billing",
      description: "Connect your revenue streams.",
      action: openBilling,
      numSourcesAdded: numConnectedDataSources.billing,
      icon: <Revenues />,
    },
    {
      label: "Accounting",
      description: "Connect your accounting data.",
      action: openAccounting,
      numSourcesAdded: numConnectedDataSources.accounting,
      icon: <Accounting />,
    },
  ];

  return (
    <DataSourceLayout
      heading="Add data source"
      description="Connecting allows us to automatically identify your contracts and sync them to your Pipe dashboard. At Pipe, we never share your data with outside third parties. This includes our investors – your data is completely anonymized. They won’t be able to see who you or your customers are."
    >
      <DataSourceList>
        {DATA_SOURCE_CATEGORIES.map(({ label, description, action, numSourcesAdded, icon }) => (
          <ListBoxItem
            key={label}
            isCenterSecondaryAction
            hasIconCircleBackground
            iconNode={<IconWrapper>{icon}</IconWrapper>}
            label={label}
            description={
              <>
                {description}
                <SourcesAddedTag>
                  {pluralizer(numSourcesAdded, "source", "sources")} added
                </SourcesAddedTag>
              </>
            }
            secondaryAction={
              <Button onClick={action} kind="secondary" size="small">
                Add source
              </Button>
            }
          />
        ))}
      </DataSourceList>
      <ContactSupport country={country ?? undefined} />
    </DataSourceLayout>
  );
};

const DataSourceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${t.space[3].toString()};
  margin: ${t.c.spacing("5", "0", "8")};

  > div:not(:last-of-type) {
    padding-bottom: ${t.space[3].toString()};
    border-bottom: ${({ theme }) => `1px solid ${theme.components.Divider.default}`};
  }
`;

const IconWrapper = styled.div`
  border-radius: ${t.radii.circle.toString()};
  color: ${({ theme }) => theme.icon.muted};
  > svg {
    display: block;
  }
`;

const SourcesAddedTag = styled.div`
  width: fit-content;

  padding: ${t.c.spacing("0", "2")};
  margin-top: ${t.space[2].toString()};

  border-radius: ${t.radii[16].toString()};

  background: ${(props) => props.theme.components.ListBox.itemBgColor};
  color: inherit;
`;
